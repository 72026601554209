MS = window.MS || {};

(function($,ddslick) {
	MS.faq = {
		$moduleFaq: $(".page-template-page-faq__faq"),

		init: function() {
			// this.$moduleFaq.find("select").selectric();

			this.$moduleFaq.find(".filter-category").on("change", $.proxy(this.toggleCategory, this));
			this.$moduleFaq.find(".filter-category-sub").on("change", $.proxy(this.toggleSubCategory, this));
			this.$moduleFaq.find(".filter-category-sub").on("click", "li", $.proxy(this.toggleSubCategory, this));
			this.$moduleFaq.find(".question-answers").on("click", ".question", $.proxy(this.toggleQuestionAnswer, this));
			this.careersRecirect();
		},

		toggleCategory: function(e) {
			var $target  = $(e.target),
					category = $target.val();

			this.$moduleFaq.find(".filter-category-sub")
				.filter("[data-category='" + category + "']")
				.addClass("is-active")
				.siblings(".filter-category-sub")
				.removeClass("is-active");

			this.$moduleFaq.find(".filter-category-sub")
				.filter("[data-category='" + category + "']")
				.closest(".selectric-filter-category-sub")
				.addClass("is-active")
				.siblings(".selectric-filter-category-sub")
				.removeClass("is-active selectric-is-active");

			this.$moduleFaq.find(".category")
				.filter("[data-category='" + category + "']")
				.addClass("is-active")
				.siblings(".category")
				.removeClass("is-active");
		},

		toggleSubCategory: function(e) {
			var $target     = $(e.target),
					subCategory = null,
					category    = $target.closest(".filter-category-sub").data("category");

			if ($target.is("select")) {
				subCategory = $target.val();
			}
			else {
				subCategory = $target.data("value");

				this.$moduleFaq.find("select.filter-category-sub")
					.filter("[data-category='" + category + "']")
					.val(subCategory);
			}

			this.$moduleFaq.find("ul.filter-category-sub")
				.filter("[data-category='" + category + "']")
				.find("[data-value='" + subCategory + "']")
				.addClass("is-active")
				.siblings("li")
				.removeClass("is-active");

			this.$moduleFaq.find(".category-sub")
				.filter("[data-category-sub='" + subCategory + "']")
				.addClass("is-active")
				.siblings(".category-sub")
				.removeClass("is-active");
		},

		toggleQuestionAnswer: function(e) {
			var $target = $(e.target);

			$target.closest(".question-answer").toggleClass("is-active");
		},

		careersRecirect: function(e) {
			$(window).load(function() {
				$('.ms-dropdown').selectric().on('change', function() {
					var _val = $(this).val();
					if( _val === "Careers" ) {
						window.location.replace("http://careers.mortonsalt.com/");
					}
				});
			});
		},

		finalize: function() {
			
		}
	};
})(jQuery);