
MS = window.MS || {};

(function( $, blockUI ) {
	MS.loader = {
		fired: false,
		show: function () {
			if( this.fired ) {
				return;
			}

			this.fired = true;
			jQuery.blockUI({
				css: {
					backgroundColor: 'transparent',
					border: 'none',
					height: '100px',
					left: 0,
					margin: 'auto',
					opacity: 1,
					right: 0,
					width: '100px',
				},
				message: '<img src="/wp-content/themes/morton-salt/loader.gif" />',
			});
		},
		hide: function() {
			if( this.fired ) {
				this.fired = false;
				jQuery.unblockUI();
				$("body").removeClass("no-scroll");
			}
		},
		preload: function(fn) {
			MS.loader.show();
			$("body").addClass("no-scroll");

			$(window).on("load", fn, MS.hide);
		}
	};
})(jQuery);
