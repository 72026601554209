
MS = window.MS || {};

(function($,ddslick) {
	MS.single_ms_recipe = {
		init: function() {
			if( $(window).width()>759 ) {
	    		$('.hero--image').height($(window).height());
	    	}
	    	MS.sticky_nav.init();
	    	$(window).resize(function() {
	    		if( $(window).width()>759 ) {
		    		$('.hero--image').height($(window).height());
		    	} else {
		    		$('.hero--image').removeAttr('style');
		    	}
	    	});
	    },
	    finalize: function() {
	    	
	    }
	};
})(jQuery);