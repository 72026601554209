MS = window.MS || {};

(function( $ ) {
	MS.foodwaste = {
		init: function() {
			this.assignEvents();
		},
		assignEvents: function() {
			if ( $('.ms-module--fw-carousel').length >= 1 ) {
				var CTAButton = $('.ms-module--fw-carousel .ms-module-caption a.ms-module-cta');
				CTAButton.on('click', function(e) {
					ga('send', 'event', {
						eventCategory: 'CTA Button',
						eventAction: e.target.innerHTML,
						eventLabel: e.target.href
					});
				});
			} else if ( $('.ms_article-food-waste-font').length >= 1 ) {
				console.log('Foodwaste Article Found');

				var blueButton = $('article .btn.btn--blue');
				blueButton.on('click', function(e) {
					ga('send', 'event', {
						eventCategory: 'Download',
						eventAction: 'Font',
						eventLabel: 'Get the font'
					});
					//console.log('Download', 'Font', 'Get the fonts');
					//console.log(ga);
				});

				$('article').on('click', 'a[data-key]', function(e) {
					var action = MS.foodwaste.toTitleCase($(this).data('key').replace(/-/g, ' '));
					var label = $(this).data('key') === 'font' ? 'Get the free font' : $(this).attr('href');
					ga('send', 'event', {
						eventCategory: 'Download',
						eventAction: action,
						eventLabel: label
					});
					//console.log('Download', action, label);
				});

			}
		},
	    toTitleCase: function (str) {
		    return str.replace(/(?:^|\s)\w/g, function(match) {
		        return match.toUpperCase();
		    });
		}
	};
})(jQuery);