
MS = window.MS || {};

(function( $ ) {
	MS.sticky_nav = {
		animate: false,
		blocks: $('.stickynav-block'),
		content: $('.content-body'),
		current_block: null,
		current_block_index: 0,
		from_block: 0,
		is_animating: false,
		is_chrome: navigator.userAgent.indexOf('Chrome') > -1,
		is_safari: navigator.userAgent.indexOf("Safari") > -1,
		nav: {
			biggest: 0,
			elements: $('.stickynav'),
			is_going_down: false,
			is_going_up: false,
			is_sticked_to_bottom: false,
			is_sticked_to_top: false,
			last_position: null,
			left: $('.stickynav--left'),
			top_margin: 70,
			position: 0,
			right: $('.stickynav--right'),
			scrolling_disabled: false,
			wrapper: $('.stickynav--wrapper')
		},
		next_block: null,
		prev_block: null,
		window: {
			bottom: 0,
			scrollTop: 0,
			height: 0,
		},
		init: function() {
			var SN = this;

			if( SN.is_safari && SN.is_chrome ) {
				SN.is_safari = false;
			}

			if( $(window).width()>759 ) {
				MS.loader.show();
			}

			$(window).load(function(){
				SN.refresh_data();
				SN.position_navs();
				$('.content-body').removeClass('hide');
				MS.loader.hide();
			});
			$(window).scroll(function() {
				SN.check_position();
			});
			$(window).resize(function() {
				SN.refresh_data();
				SN.position_navs();
				SN.check_position();
			});
			
		},

		refresh_data: function() {
			var SN = this;
			if( $(window).width()>759 ) {
				$w = $(window);
				$('.content-body').css('margin-top',$(window).height() - 484);
				SN.window.height = $w.height();
				SN.window.scrollTop = $w.scrollTop();
				SN.window.bottom = SN.window.scrollTop + SN.window.height;
				SN.nav.is_going_down = false;
				SN.nav.is_going_up = false;
				SN.nav.elements.each(function() {
	    			$obj = $(this);
	    			$obj.css({
	    				height: $obj.outerHeight(true),
	    				width: 70
	    			});
	    			if( $obj.outerHeight(true) > SN.nav.biggest ) {
	    				SN.nav.biggest = $obj.outerHeight(true);
	    			}
	    		});
	    		SN.nav.wrapper.height(SN.nav.biggest);
	    		SN.update_blocks();
	    	} else {
	    		$('.content-body').removeAttr('style');
	    		SN.nav.wrapper.removeAttr('style');
	    		MS.loader.hide();
	    	}
		},

		position_navs: function() {
			var SN = this;
			if( $(window).width()>759 ) {
				var _left = SN.blocks.first().offset().left - SN.nav.left.width() - parseInt(SN.blocks.first().css('padding-left').replace('px',''));
				var _right = _left + SN.blocks.first().outerWidth() + SN.nav.right.width();
				SN.nav.left.css('left',_left);
				SN.nav.right.css('left',_right);

				SN.nav.position = SN.window.bottom - SN.nav.wrapper.height() - SN.current_block.offset().top + SN.current_block.data('top');

				if( SN.block_top() < 0 && ( SN.current_block_index - 1 ) >= 0 ) {
	    			SN.current_block_index--;
	    			SN.current_block.removeClass('stickynav-block-current');
	    			SN.current_block = SN.blocks.eq(SN.current_block_index);
					SN.next_block = SN.blocks.eq(SN.current_block_index+1);
					SN.prev_block = SN.blocks.eq(SN.current_block_index-1);
	    			SN.stick_to_bottom();
	    		} else if( SN.current_block_index === 0 && SN.block_top() < 0 ) {
	    			SN.stick_to_top();
	    		} else if( SN.nav.position > SN.block_bottom() ) {
	    			SN.stick_to_bottom();
	    		}
			} else {
				SN.nav.elements.removeAttr('style');
				SN.nav.wrapper.removeAttr('style');
			}
		},

		update_blocks: function() {
			var SN = this;
			var blockTop = 0;
			SN.blocks.each(function(index,e) {
				$block = $(e);
				blockTop += parseInt( $block.css('margin-top').replace('px','') );

				if( index === 0 ) {
					$block.attr('data-top', 0);
				} else {
					if( typeof event === "undefined" || event.type==='resize' ) {
						$block.data('top', blockTop);
					} else {
						$block.attr('data-top', blockTop);
					}
				}
				
				blockTop += $block.innerHeight();

				// get the block that is currently on screen
				if( ( SN.window.bottom >= $block.offset().top && SN.window.bottom <= $block.offset().top + $block.outerHeight(true) ) || ( index === (SN.blocks.length - 1) && SN.window.bottom > $block.offset().top + $block.outerHeight(true) ) ) {
					SN.blocks.removeClass('stickynav-block-current');
					$block.addClass('stickynav-block-current');
					SN.current_block_index = index;
					SN.current_block = SN.blocks.eq(index);
					SN.next_block = SN.blocks.eq(index+1);
					SN.prev_block = SN.blocks.eq(index-1);
				}
			});
		},

		get_scroll_direction: function() {
			var SN = this;
			var st = SN.window.scrollTop;
			if (st > SN.nav.last_position){
				SN.nav.is_going_down = true;
				SN.nav.is_going_up = false;
			} else {
				SN.nav.is_going_down = false;
				SN.nav.is_going_up = true;
			}
			SN.nav.last_position = st;
		},

		check_position: function () {
			var SN = this;

			if( SN.current_block === null || $(window).width()<760 ) {
				return;
			}

			SN.window.scrollTop = $(window).scrollTop();
			SN.window.bottom = SN.window.scrollTop + SN.window.height;
			SN.get_scroll_direction();

			if( SN.nav.is_sticked_to_bottom && SN.nav.is_going_up && SN.window.bottom <= (SN.current_block.offset().top + SN.current_block.innerHeight()) ) {
    			SN.nav.scrolling_disabled = false;
    			SN.nav.is_sticked_to_bottom = false;
    			if( SN.is_animating ) {
    				SN.nav.wrapper.stop();
    				SN.animate = false;
					SN.is_animating = false;
					SN.window.bottom = SN.window.scrollTop + SN.window.height;
    			}
    			SN.nav.wrapper.removeAttr('style').height(SN.nav.biggest).removeClass('stickynav--wrapper--disabled');
    		} else if( SN.nav.is_sticked_to_top && SN.nav.is_going_down && (SN.window.bottom - SN.nav.biggest) >= SN.current_block.offset().top ) {
    			SN.nav.scrolling_disabled = false;
    			SN.nav.is_sticked_to_top = false;

    			if( SN.from_block === SN.current_block_index ){
    				SN.nav.wrapper.removeAttr('style').height(SN.nav.biggest).removeClass('stickynav--wrapper--disabled');
    			}
    		}

			SN.nav.position = SN.window.bottom - SN.nav.biggest - SN.current_block.offset().top + SN.current_block.data('top');

			if( SN.nav.is_going_down && (SN.current_block_index + 1) <= SN.blocks.length && SN.nav.position >= SN.next_block.data('top') ) {
	    		if(!SN.nav.is_sticked_to_bottom) {
	    			SN.stick_to_bottom();
	    		}

    			SN.nav.is_sticked_to_top = true;
    			SN.nav.is_sticked_to_bottom = false;
    			SN.from_block = SN.current_block_index;
    			SN.current_block_index++;
    			SN.current_block.removeClass('stickynav-block-current');
    			SN.blocks.eq(SN.current_block_index).addClass('stickynav-block-current');
    			SN.current_block = SN.blocks.eq(SN.current_block_index);
				SN.next_block = SN.blocks.eq(SN.current_block_index+1);
				SN.prev_block = SN.blocks.eq(SN.current_block_index-1);
    			SN.animate = true;
				SN.stick_to_top();
    			return;
	    	} else if( SN.nav.is_going_up && (SN.current_block_index - 1) >= 0 && SN.nav.position <= SN.current_block.data('top') ) {
	    		if(!SN.nav.is_sticked_to_top) {
	    			console.log(1);
	    			SN.stick_to_top();
	    		}
	    		console.log(2);
    			SN.nav.is_sticked_to_bottom = true;
    			SN.nav.is_sticked_to_top = false;
    			SN.from_block = SN.current_block_index;
    			SN.current_block_index--;
    			SN.current_block.removeClass('stickynav-block-current');
    			SN.blocks.eq(SN.current_block_index).addClass('stickynav-block-current');
    			SN.current_block = SN.blocks.eq(SN.current_block_index);
				SN.next_block = SN.blocks.eq(SN.current_block_index+1);
				SN.prev_block = SN.blocks.eq(SN.current_block_index-1);
    			SN.animate = true;
				SN.stick_to_bottom();
				return;
	    	} else if( SN.nav.is_sticked_to_top && SN.nav.is_going_down && SN.nav.position >= SN.current_block.data('top') ) {
	    		SN.nav.is_sticked_to_top = false;
	    		SN.nav.scrolling_disabled = false;
	    		SN.nav.wrapper.removeAttr('style').height(SN.nav.biggest).removeClass('stickynav--wrapper--disabled');
	    	}

	    	if( SN.nav.is_going_down && SN.window.bottom >= (SN.current_block.offset().top + SN.current_block.innerHeight()) ) {
    			SN.stick_to_bottom();
    		} else if( SN.nav.is_going_up && (SN.window.bottom - SN.nav.biggest) <= SN.current_block.offset().top  ) {
    			SN.stick_to_top();
    			if( SN.current_block_index === 0 ) {
    				SN.from_block = 0;
    			}
    		}

    		if( SN.is_safari ) {
    			SN.content.hide();
    			SN.content.show();
    		}
    		
		},

		stick_to_bottom: function() {
			var SN = this;

			if( SN.current_block.hasClass('block-1') ) {
				return;
			}

			var _bottom = SN.current_block.innerHeight() - SN.nav.wrapper.height() + SN.current_block.data('top');

			SN.nav.position = SN.window.bottom - SN.nav.wrapper.height() - SN.current_block.offset().top + SN.current_block.data('top');
			SN.nav.scrolling_disabled = true;
			SN.nav.is_sticked_to_bottom = true;
			SN.nav.wrapper.addClass('stickynav--wrapper--disabled');

			if( !SN.is_animating && SN.animate && SN.nav.position > _bottom ) {
				SN.is_animating = true;
				SN.nav.wrapper.stop().animate({
					top: _bottom
				}, 200, function() {
					SN.animate = false;
					SN.is_animating = false;
					SN.window.bottom = SN.window.scrollTop + SN.window.height;
					SN.nav.position = SN.window.bottom - SN.nav.wrapper.height() - SN.current_block.offset().top + SN.current_block.data('top');
					if( (SN.nav.position < _bottom || parseInt(SN.nav.wrapper.css('top').replace('px', '')) < SN.current_block.data('top')) && SN.next_block.length > 0 ) {
						SN.nav.wrapper.removeAttr('style').height(SN.nav.biggest).removeClass('stickynav--wrapper--disabled');
					}
				});
			} else if( SN.is_animating && SN.nav.position > _bottom ) {
				SN.nav.wrapper.stop();
				SN.is_animating = false;
				SN.animate = false;
				SN.nav.wrapper.css('top', _bottom + 'px');
			} else {
				SN.nav.wrapper.css('top', _bottom + 'px');
			}
		},

		stick_to_top: function() {
			var SN = this;

			if( SN.is_animating || SN.current_block.hasClass('block-2') ) {
				return;
			}

			SN.nav.scrolling_disabled = true;
			SN.nav.is_sticked_to_top = true;
			SN.nav.position = SN.current_block.data('top');
			SN.nav.wrapper.addClass('stickynav--wrapper--disabled');

			if( SN.animate ) {
				SN.is_animating = true;
				SN.nav.wrapper.stop().animate({
					top: SN.nav.position
				}, 200, function() {
					SN.animate = false;
					SN.is_animating = false;
					SN.nav.wrapper.removeAttr('style').height(SN.nav.biggest).removeClass('stickynav--wrapper--disabled');
				});
			} else {
				SN.nav.wrapper.css('top', SN.nav.position + 'px');
			}
		},

		block_bottom: function() {
			var SN = this;
			return SN.current_block.data('top') - SN.nav.biggest + SN.current_block.innerHeight();
		},

		block_top: function() {
			var SN = this;
			return SN.window.bottom - SN.current_block.offset().top - SN.nav.biggest;
		}
	};
})(jQuery);
