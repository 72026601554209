MS = window.MS || {};

(function($) {
	MS.home = {
    selectors: {
      module: '.page-template-page-home__hero',
      cta: '.btn-anchor'
    },
		init: function() {
      this.$module = $( this.selectors.module );
      this.$ctaAnchor = $( this.selectors.cta, this.$module );
      this.handleCTA();
		},

		handleCTA: function() {
      if( this.$ctaAnchor.length > 0 ) {
        var _this = this;
        this.$ctaAnchor.on('click', function(e){
          e.preventDefault();
          var $target = $($(this).attr('href')).first();
          $([document.documentElement, document.body]).animate({
            scrollTop: $target.offset().top - 100
        }, 900);
        });
      }
    },

		finalize: function() {

		}
	};
})(jQuery);
