MS = window.MS || {};

(function( $ ) {
	MS.footer = {
		init: function() {
      this.$siteDisclaimerContainer = $('.ms-privacy-disclaimer');
      this.$siteDisclaimerBtn = $('.ms-privacy-disclaimer__close-btn', this.$siteDisclaimerContainer);
      this.setupDisclaimer();
		},

    setupDisclaimer: function() {
      var _this = this;
      var mortonDisclaimer = localStorage.getItem('mortonDisclaimer');
      if (!mortonDisclaimer) {
        this.$siteDisclaimerContainer.addClass('active');

        setTimeout(function() {
          _this.$siteDisclaimerContainer.addClass('animate-in');
        }, 400);
      }

      // clicking close button will acknowledge that user have read disclaimer
      this.$siteDisclaimerBtn.on('click', function() {
        localStorage.setItem('mortonDisclaimer', 'acknowledged');
        _this.$siteDisclaimerContainer.removeClass('animate-in');

        setTimeout(function() {
          _this.$siteDisclaimerContainer.removeClass('active');
        }, 400);
      });
    },

		finalize: function() {
		}
	};
})(jQuery);
