MS = window.MS || {};

(function($) {
	MS.store_locator= {
		$module: $('.module-template-store-locator'),

		init: function() {
			// this.$module.find("#form-store-locator").on("change", ".field", this.changeHandler);
			this.$module.find("#form-store-locator").on('click', 'button.go', this.changeHandler);
			this.$module.find("#form-store-locator").on('keyup', '.field-zip', this.keyupHandler);
			this.$module.find(".online-stores")
				.randomize(".online-store")
				.addClass("is-randomized");
		},

		keyupHandler: function(e) {
			if (e.which === 13) {
				var $target = $(e.target),
						$form   = $target.closest("form");

				MS.store_locator.getStores($form);
			}
		},

		changeHandler: function(e) {
			var $target = $(e.target),
					$form   = $target.closest("form");

			MS.store_locator.getStores($form);
		},

		getStores: function($form) {
			if ($form) {
				var $result = $(".store-locator-result"),
						item    = $form.find(".field-product").val(),
						zip     = $form.find(".field-zip").val(),
						radius  = $form.find(".field-radius").val();

				if (item && zip && radius) {
					MS.loader.show();

					$.getJSON("https://www2.itemlocator.net/ils/locatorJSON/?callback=?", {
						customer: "morton",
						item: item,
						zip: zip,
						radius: radius
					})
					.done(function(json) {
						$form.removeClass("no-result error");
						$result
							.html("")
							.closest(".grid-2")
							.removeClass("has-stores");

						if (json.nearbyStores.length > 0) {
							var html = "";

							$.each(json.nearbyStores, function(key, val) {
								var _phone = val.phone.match(/\d+/g).join('');
								html += "<li class='store'>";
								html += "<h4>" + val.name + " <em>" + val.distance + " miles</em></h4>";
								html += "<p>" + val.address + " " + val.address2 + "<br>" + val.city + " " + val.state + ", " + val.zip + "</p>";
								html += "<p class='phone'>T: <a href='tel:" + _phone + "'>" + val.phone + "</a></p>";
								html += "</li>";
              });

							$result
								.html(html)
								.closest(".grid-2")
                .addClass("has-stores");

                if($('.stores').hasClass('ms-ts-store-locator-result')) {
                  MS.tableandsalt_store_locator.init(); //initialize pagination for table and salt template
                }
						}
						else {
							$form.addClass("no-result");
						}
					})
					.fail(function(jqxhr, textStatus, error) {
						$form.addClass("error");
					})
					.always(function() {
						MS.loader.hide();
					});
				}
			}
		},

		finalize: function(){

		}
	};


})(jQuery);
