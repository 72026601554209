/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var MS = {};

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	MS = {
		// All pages
		'common': {
			init: function() {
			// JavaScript to be fired on all pages
				MS.header.init();
				MS.navigation.init();
				MS.forms.init();
				MS.dropdown.init();
				MS.tables.init();
				MS.scrollbar.init();
				MS.tabs.init();
				MS.acc.init();
				MS.sharing.init();
				MS.video.init();
				MS.store_locator.init();
				MS.carousel.init();
				MS.modal.init();
				MS.foodwaste.init();
				MS.footer.init();

				var scrollTop = 0;

				if (MS.isIOS()) {
					$("body")
						.addClass("is-ios")
						.on("stopscroll", function(e) {
							scrollTop = $(document).scrollTop();
						})
						.on("startscroll", function(e) {
							$(document).scrollTop(scrollTop);
						});
				}

				if (MS.isAndroid()) {
					$("body")
						.addClass("is-android")
						.on("stopscroll", function(e) {
							scrollTop = $(document).scrollTop();
						})
						.on("startscroll", function(e) {
							setTimeout(function() {
								$(document).scrollTop(scrollTop);
							}, 1);
						});
				}

				if ($("#wpadminbar").length) {
					var $toggle = $("<button>");
					$toggle.html("Toggle Image Size");
					$toggle.addClass("image-size-toggle");
					$toggle.insertAfter("#wp-admin-bar-my-account");
					$toggle.on("click", function() {
						$toggle.toggleClass("active");

						if ($toggle.hasClass("active")) {
							$('img').one("load", function() {
								var $this = $(this);

								if ($this.attr("src").indexOf(window.location.host) > -1) {
									var $div = $("<div>");
									$div.css({"top": $this.offset().top, "left": $this.offset().left});
									$div.attr("data-src", $this.attr("src"));
									$div.html(this.naturalWidth + "x" + this.naturalHeight);
									$div.addClass("image-size");

									$("body").append($div);
								}

							}).each(function() {
								if (this.complete) {
									$(this).load();
								}
							});
						}
						else {
							$(".image-size").remove();
						}
					});

					$(window).on("resize", function() {
						$(".image-size").remove();
					});
				}
			},
			finalize: function() {
			// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		'sharing': {
			init: function() {
				if( !$(".expandable-share").length ){ return; }

				$(".expandable-share__drawer__item").on("click", function(e){
					e.preventDefault();

					var ms_popup;

					ms_popup = ($(this).hasClass("pn"))? window.open($(this).attr('href'), 'Share', 'height=600,width=800') : window.open($(this).attr('href'), 'Share', 'height=300,width=600');

					ga('send', 'event', 'button', 'click', $(this).attr("data-track"));

							if (window.focus) {
						ms_popup.focus();
					}
				});

				if( !$("html").hasClass("no-touch") ) {
					$(".expandable-share").on("click", function(e){
						e.preventDefault();
						var $drawer = $(".expandable-share__drawer");
						if( $(window).width() < 760 && $drawer.offset().top - $drawer.height() >= 0 ) {
							$drawer.css('bottom', 'auto');
						} else {
							$drawer.css('bottom', '');
						}
						$(".expandable-share__drawer").toggle();

					});
				} else {
					$(".expandable-share").hover(
						function() {
							var $drawer = $(".expandable-share__drawer");
							if( $(window).width() < 760 && $drawer.offset().top - $drawer.height() >= 0 ) {
								$drawer.css('bottom', 'auto');
							} else {
								$drawer.css('bottom', '');
							}
							$drawer.show();

						},
						function() {
							$(".expandable-share__drawer").hide();
						}
					);
				}
			}
		},
		'page_template_page_home': {
			init: function() {
				if( !$(".one-offs--grid").length ){ return; }

				$(window).load(function() {
					MS.loader.hide();
					$("body").removeClass("no-scroll");

					$(".one-offs--grid").fadeIn().equalize();
				});

				$(window).resize(function(){
					$(".one-offs--grid").equalize({
						equalize: 'height',
						reset: true
					});
				});
			}
		},
		// Business Category Taxonomy
		'tax_ms_business_category': {
			init: function() {
				if( !$(".browse-filter").length ){ return; }

				$(".browse-filter").on("click", function(e){
					e.preventDefault();
					var theClass = $(this).attr("href");

					$(".browse-items").find("article").not(theClass).hide();
					$(".browse-items").find(theClass).show();
				});
			},
			finalize: function() {

			}
		},
		// Single Product Detail
		'single_ms_home_product': {
			init: function() {
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
			// JavaScript to be fired on the about us page
			}
		},
		isIOS: function() {
			var iDevices = [
				// 'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				// 'iPad',
				'iPhone',
				'iPod'
			];

			while (iDevices.length) {
				if (navigator.platform === iDevices.pop()){ return true; }
			}

			return false;
		},
		isAndroid: function() {
			var ua = navigator.userAgent.toLowerCase();

			return ua.indexOf("android") > -1;
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = MS;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	$.fn.randomize = function(selector){
		(selector ? this.find(selector) : this).parent().each(function(){
			$(this).children(selector).sort(function(){
				return Math.random() - 0.5;
			}).detach().appendTo(this);
		});

		return this;
	};

})(jQuery); // Fully reference jQuery after this point.
