
MS = window.MS || {};

(function( $, accordion ) {
	MS.acc = {
		init: function () {
			$(window).load(function() {
				$('.ms-accordion').accordion({active:false,collapsible:true,heightStyle:'content'});
			});
		},
	};
})(jQuery);
