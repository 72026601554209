
MS = window.MS || {};

(function($,ddslick) {
	MS.single_ms_recipe_book = {
		init: function() {
	    	$('select[name=recipe_books]').change(function(e) {
	    		e.preventDefault();

	    		if( $(this).val()==="" ) {
	    			return;
	    		}

	    		document.location = $(this).val();
	    	});
	    },
	    finalize: function() {
	    	
	    }
	};
})(jQuery);