
MS = window.MS || {};

(function( $ ) {
	MS.page_template_page_ts_landing = {
		$window: $(window),
		$moduleSocial : $('.ms-social-module'),
		init: function() {
			var self = this;
			$('.ms-ts-landing__hero, .ms-ts-gallery-module__images').slick();

			var $status = $('.ms-ts-gallery-module__nav span.page');
			$('.ms-ts-gallery-module__images').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
			    var i = (currentSlide ? currentSlide : 0) + 1;
			    var $gallery = $(this);
			    $status.text(i + '/' + slick.slideCount);

			    var $activeVideo = $gallery.find('.ms-video.active').first();
			    if( $activeVideo.length > 0 ) {
			    	var _id = $activeVideo.find('.ms-video__wrapper').attr('id');
			    	$activeVideo.find('.ms-video__wrapper').remove();
			    	$activeVideo.prepend('<div id="' + _id + '" class="ms-video__wrapper"></div>');
			    	$activeVideo.removeClass('active');
			    }
			});

			$('.ms-ts-gallery-module__images').on('click', '.video > a', function(event) {
				event.preventDefault();
				$trigger = $(this);
				$parent = $trigger.parents('.video').first();
				$parent.addClass('video--active');
			});
			
			this.$moduleSocial.on("click", ".btn-load-more", this.loadMoreSocialItems);
			this.filterDropdown();
			this.tabSelection();
		},
		loadMoreSocialItems: function() {
			var $button 	= $(this),
				$wrapper 	= $button.parents('.btn-load-more__wrapper').first(),
				$page_id 	= $button.data('page-id'),
				$page_no 	= $button.data('page-no'),
				$total 		= $button.data('total-count');
								
			$.ajax({
				url: msGlobal.ajaxUrl,
				type: "post",
				data: {
					action: "get_social_media_items",
					page_id: $page_id,
					page_no: $page_no
				},
				beforeSend: function() {
					MS.loader.show();
				},
				success: function(result) {
					
					if ( result ) {
						$wrapper.before(result);
						
						if ($total <= ($page_no + 1) * 3) {
							$button.hide();
						} else {
							$button.data('page-no', $page_no + 1);
						}
					}
					
					MS.loader.hide();
				}
			});
		},
		filterDropdown: function() {
			$('.ms-ts-landing__filter h4').click(function(e) {
				$('.ms-ts-landing__filter').toggleClass('active');
			});
		},
		tabSelection: function() {
			$('.ms-ts-landing__filter-selection li a').click(function(e) {
				e.preventDefault();
				$('.ms-ts-landing__filter-selection li, .ms-ts-landing__city-wrapper').removeClass('active').removeAttr('style');
				$(this).parent('li').addClass('active');
				$($(this).attr('href')).fadeIn(800, function() {
					$(this).addClass('active');
				});
				
				// Update slick sliders
				$('.ms-ts-landing__hero, .ms-ts-gallery-module__images').slick('unslick');
				$('.ms-ts-landing__hero, .ms-ts-gallery-module__images').slick();
				
				$('.ms-ts-landing__filter').removeClass('active');

				// Update Selected Text
				$('.ms-ts-landing__filter-selected span').text($(this).find('.ms-ts-landing__filter__name').text());
			});
		},
		finalize: function(){

		}
	};
})(jQuery);
