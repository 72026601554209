MS = window.MS || {};

(function($) {
	MS.video = {
		init: function() {
			$(document).on("click", ".ms-video-link", this.showVideo);
		},

		showVideo: function(e) {
			e.preventDefault();

			var $target  = $(e.target),
					$link    = $target.closest("a"),
					videoUrl = $link.attr("href"),
					videoId  = MS.video.extractVideoId(videoUrl),
					videoW   = 1120,
					videoH   = 630,
					autoplay = '';

			if( $target.data('autoplay') !== "undefined" && $target.data('autoplay') === true ) {
				autoplay = '&autoplay=1';
			}

			var html = '<i class="icon-cross"></i>';
			html += '<div class="container-video-outer"><div class="container-video-inner">';
			html += '<iframe width="' + videoW + '" height="' + videoH + '" src="https://www.youtube.com/embed/' + videoId + '?rel=0&color=white' + autoplay + '" frameborder="0" allowfullscreen></iframe>';
			html += '</div></div>';

			$.blockUI({
				message: html,
				css: {
					backgroundColor: "#B2B6DA",
					border: "none",
					cursor: "default",
					height: "auto",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
					maxWidth: "100%",
					width: 1200
				},
				overlayCSS: {
					cursor: "default"
				},
				baseZ: 1200,
				blockMsgClass: "ms-video-player",
				onBlock: function() {
					$(".ms-video-player .icon-cross").one("click", MS.video.hideVideo);
				}
			});

			$(window).on("resize", MS.video.resizeModal);
			MS.video.resizeModal();
		},

		hideVideo: function(e) {
			$.unblockUI();
			$(window).off("resize", MS.video.resizeModal);
		},

		extractVideoId: function(url) {
			var videoId = url.split('v=')[1];
			var ampersandPosition = videoId.indexOf('&');
			
			if (ampersandPosition !== -1) {
				videoId = videoId.substring(0, ampersandPosition);
			}

			return videoId;
		},

		resizeModal: function(e) {
			var $modal  = $(".ms-video-player");

			if ($modal.length) {
				var $window = $(window),
						windowH = $window.innerHeight();

				// if (windowH > 320) {
					$modal.width(1200);

					var modalH  = $modal.innerHeight(),
							modalW  = $modal.innerWidth(); 

					while (modalH > windowH) {
						$modal.width(modalW--);
						modalH = $modal.innerHeight();
					}
				// }
			}
		},

		finalize: function() {
			
		}
	};
})(jQuery);