MS = window.MS || {};

(function($) {
	MS.navigation = {
		$document: $(document),
		$body: $("body"),
		$header: $("#header"),
		$navigation: $("#navigation"),

		init: function() {
			this.$header.on("click", ".btn-navigation", $.proxy(this.toggleNav, this));
			this.$document.on("click touchend", $.proxy(this.retractNav, this));

			this.$navigation.on("click", "a", $.proxy(this.expandSubnav, this));
			this.$navigation.on("click", ".btn-back, .btn-menu", $.proxy(this.retractSubnav, this));

			this.$navigation.on("click", ".btn-search", $.proxy(this.expandSerach, this));
			this.$document.on("click touchend", $.proxy(this.retractSearch, this));

			this.$navigation.on("transitionend", $.proxy(this.navTransitionCompleted, this));
		},

		toggleNav: function(e) {
			if (!this.$navigation.hasClass("is-animating")) {
				this.$navigation.addClass("is-animating");

				$(e.currentTarget).toggleClass("is-active", !this.$navigation.hasClass("is-expanded"));

				if (this.$navigation.hasClass("is-expanded")) {
					this.$navigation
						.removeClass("is-expanded is-expanded-nav-sub is-expanded-nav-sub-wide")
						.find(".nav-sub.is-expanded")
						.addClass("is-animating")
						.removeClass("is-expanded");

					if ($("body").hasClass("is-ios")) {
						this.$document.scrollTop(this.scrollTop);
					}

					this.$body
						.trigger("startscroll")
						.removeClass("no-scroll no-click");
				}
				else {
					this.$navigation.addClass("is-expanded");

					this.$body
						.trigger("stopscroll")
						.addClass("no-scroll no-click");
				}
			}
		},

		retractNav: function(e) {
			if (!this.$navigation.hasClass("is-animating")) {
				if (this.$navigation.hasClass("is-expanded")) {
					if (!$(e.target).closest(".btn-navigation, #navigation").length) {
						this.$header.find(".btn-navigation").removeClass("is-active");

						this.$navigation
							.addClass("is-animating")
							.removeClass("is-expanded is-expanded-nav-sub is-expanded-nav-sub-wide")
							.find(".nav-sub.is-expanded")
							.addClass("is-animating")
							.removeClass("is-expanded");

						this.$body
							.trigger("startscroll")
							.removeClass("no-scroll no-click");
					}
				}
			}
		},

		navTransitionCompleted: function(e) {
			this.$navigation
				.removeClass("is-animating")
				.find(".is-animating")
				.removeClass("is-animating");
		},

		expandSubnav: function(e) {
			var $target = $(e.target).closest("a"),
					$subNav = $target.next(".nav-sub");

			if ($subNav.length) {
				e.preventDefault();

				if ($subNav.hasClass("wide")) {
					this.$navigation.addClass("is-expanded-nav-sub-wide is-animating");
				}
				else {
					this.$navigation.addClass("is-expanded-nav-sub is-animating");
				}

				$subNav.addClass("is-expanded is-animating");
			}
		},

		retractSubnav: function(e) {
			this.$navigation
				.addClass("is-animating")
				.removeClass("is-expanded-nav-sub is-expanded-nav-sub-wide")
				.find(".nav-sub.is-expanded")
				.addClass("is-animating")
				.removeClass("is-expanded");
		},

		expandSerach: function(e) {
			var $searchForm = this.$navigation.find(".form-search");

			if (!$searchForm.hasClass("is-active")) {
				e.preventDefault();

				$searchForm
					.addClass("is-active")
					.find("input[type=text]")
					.focus();
			}
		},

		retractSearch: function(e) {
			if (!$(e.target).closest(".form-search").length) {
				this.$navigation.find(".form-search").removeClass("is-active");
			}
		},

		finalize: function() {
			
		}
	};
})(jQuery);