MS = window.MS || {};

(function($) {
	MS.inlineVideo = {
		apiLoaded: false,
		player: null,
		videoId: null,
		$parent: null,
		$wrapper: null,
		$trigger: null,
		init: function() {
			var self = this;
			
			$('.ms-video').on('click', '.ms-video__trigger', function(e) {
				e.preventDefault();
				self.$trigger = $(this);
				self.$parent = $(this).parents('.ms-video').first();
				self.$wrapper = self.$parent.find('.ms-video__wrapper').first();
				self.play();
			});
		},
		play: function() {
			var self = this;
			var _id = self.$trigger.attr('href');
			self.videoId = _id;
			self.$parent.addClass('active');
			MS.inlineVideo.player = new YT.Player(self.$wrapper.attr('id'), {events: {'onReady': MS.inlineVideo.onPlayerReady, 'onStateChange': MS.inlineVideo.onPlayerStateChange}, playerVars: MS.inlineVideo.getPlayerSettings()});
		},
		onPlayerReady: function() {
			MS.inlineVideo.player.loadVideoById({'videoId': MS.inlineVideo.videoId, 'suggestedQuality': 'hd720'});
		},
		onPlayerStateChange: function(e) {
			
		},
		getPlayerSettings: function() {
			var settings = {
				autoplay: 1, 
				autohide: 1, 
				modestbranding: 0, 
				rel: 0, 
				showinfo: 0, 
				controls: 1, 
				disablekb: 1, 
				enablejsapi: 0, 
				iv_load_policy: 3
			};
			return settings;
		},
		close: function($p) {
			$activeVideos = $p.find('.ms-video iframe');
			$.each($activeVideos, function(index, val) {
				var $obj = $(this);
				var $wrapper = $obj.parents('.ms-video').first();
				$wrapper.removeClass('active').prepend('<di id="' + $obj.attr('id') + '" class="ms-video__wrapper"></div>');
				$obj.remove();
			});
		}
	};
})(jQuery);

jQuery(document).ready(function($) {
	MS.inlineVideo.init();
});