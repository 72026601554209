
MS = window.MS || {};

(function( $, accordion ) {
	MS.carousel = {
		cycle: false,
		init: function () {
			$(window).load(function() {
				MS.carousel.prepare();
			});
			$(window).resize(function() {
				$.each($( '.ms-media__image--carousel' ), function(i, val) {
					 $wrapper = $(this);
					 $images = $wrapper.find('.images');
					 $images.height( $images.find('img.active').height() );
				});
			});
		},

		prepare: function() {
			$.each($( '.ms-media__image--carousel' ), function(i, val) {
				var $obj = $(this);
				var temp = '<div class="images">' + $obj.html() + '</div>';
				$obj.addClass('active');
				$obj.html('<div class="pagination"><span class="current">1</span> <span class="divider"></span> <span class="total"></span></div><ul class="pagination__buttons"><li><a class="next" href="javascript:;"><i class="icon-next"></i></a></li><li><a class="prev" href="javascript:;"><i class="icon-previous"></i></a></li></ul><div class="caption"></div>').append(temp);

				$obj.find('.pagination .total').html( $obj.find('img').length );
				MS.carousel.display( i, 0 );
			});

			$( '.ms-media__image--carousel' ).on('click', '.pagination__buttons a:not(.disabled)', function(e) {
				e.preventDefault();
				var $obj = $(this);
				var $wrapper = $obj.parents('.ms-media__image--carousel').first();
				var currentPage = parseInt( $wrapper.find( '.pagination .current' ).html() );
				var page = null;
				if( $obj.hasClass('next') ) {
					page = currentPage + 1;
				} else {
					page = currentPage - 1;
				}

				if( MS.carousel.cycle ) {
					if( page > $wrapper.find('img').length ) {
						page = 1;
					} else if( page < 1 ) {
						page = $wrapper.find('img').length;
					}

					MS.carousel.display( $wrapper.index($wrapper), page - 1 );
				} else {
					if( page >= 1 && page <= $wrapper.find('img').length ) {
						MS.carousel.display( $wrapper.index($wrapper), page - 1 );
					}
				}
			});
		},

		display: function( i, img ) {
			var $wrapper = $( '.ms-media__image--carousel' ).eq( i );
			var $images = $wrapper.find('.images img');
			var $lastActive = $wrapper.find('img.active');
			var $newActive = $images.eq( img );
			var _caption = '';
			$newActive.addClass('fading-in');

			if( !MS.carousel.cycle ) {
				$wrapper.find('.pagination__buttons a').removeClass('disabled');
				if( img === 0 ) {
					$wrapper.find('.pagination__buttons a.prev').addClass('disabled');
				} else if( img === ( $images.length - 1 ) ) {
					$wrapper.find('.pagination__buttons a.next').addClass('disabled');
				}
			}	
			
			if( typeof(event) !== 'undefined' && event.type !== 'click' ) {
				$images.hide();
				$wrapper.find('.images').height($newActive.height());
				$wrapper.find('.caption').html( $newActive.data('caption') );
				$newActive.fadeIn().addClass('active');
				return;
			}

			$newActive.stop().fadeIn(function(){
				$images.removeClass('active').hide();
				$newActive.removeClass('fading-in').addClass('active').show();
				$wrapper.find('.images').height($newActive.height());
			});

			$wrapper.find( '.pagination .current' ).html(img+1);
			if( $newActive.data('caption') ) {
				_caption = $newActive.data('caption');
			} else {
				_caption = '';
			}
			$wrapper.find('.caption').html( $newActive.data('caption') );
		}
	};
})(jQuery);
