
MS = window.MS || {};

(function( $ ) {

	$browse_items_container = $(document).find(".browse-items");
	$browse_items_pagination = $(document).find(".browse-items__pagination");

	$prevBtn = $(".browse-items__pagination__extend--prev");
	$nextBtn = $(".browse-items__pagination__extend--next");

	$ms_ajax_query_url = $browse_items_container.data("ajax-url");

	$ms_ajax_query = {
		'post_type'				:	$browse_items_container.data("post-type"),
		'posts_per_page'		:	$browse_items_container.data("posts-per-page"),
		'status'				:	$browse_items_container.data("status"),
		'tax_query_taxonomy'	:	$browse_items_container.data("tax-query-taxonomy"),
		'tax_query_field'		:	$browse_items_container.data("tax-query-field"),
		'tax_query_terms'		:	$browse_items_container.data("tax-query-terms"),
		'offset'				:	$browse_items_container.data("tax-offset"),
		'parent_term'			:	$browse_items_container.data("tax-query-parent-term"),
	};

	$ms_ajax_paging = {
		'prev'					:	0,
		'current'				:	1,
		'next'					:	2,
		'page'					:	1,
		'update_paging'			:	false,
		'total'					:	$browse_items_container.data("total-set"),
		'pages'					:	Math.ceil( $browse_items_container.data("total-pages") / $ms_ajax_query.posts_per_page),
	};

/*********************************************************************************************/
/*********************************** HOME CATEGORY *******************************************/
/*********************************************************************************************/

	MS.tax_ms_home_category = {
		init: function(){

			if( !$(".browse-items").length ){ return; }

			$(".selectric-ms-dropdown").on("change", function(e){
				var theClass = $(e.target).val();

				$(".browse-items").find("article").not(theClass).hide();
				$(".browse-items").find(theClass).show();
			});

			MS.tax_ms_home_category._drawButtons( $ms_ajax_paging );
			MS.product_category_helper._updateBrowseItemsHeight();

			$('body').on('change', '.browse-tabs__dropdown', function(e) {
				e.preventDefault();
				$ms_ajax_paging.page = $(this).val();
				MS.tax_ms_home_category._filter();
				MS.tax_ms_home_category._fetch();
			});

			$("body").on("click", ".ajax-pagination-item", function(e){
				e.preventDefault();
				$this = $(this); // point to the button clicked

				if( $this.hasClass("browse-filter") ) {
					$ms_ajax_paging.page = $this.data("filter");
					MS.tax_ms_home_category._filter();
				} else if ( $this.hasClass("browse-items__pagination__item") ) {
					$ms_ajax_paging.page = $this.attr("href");

					// IF A SET NUMBER IS CLICKED
					$ms_ajax_query.offset = (Math.round($this.data("target")) - 1) * $ms_ajax_query.posts_per_page;

					$ms_ajax_paging.update_paging = false;

				} else if ( $this.hasClass("browse-items__pagination__extend") ) {
					// IF PREV/NEXT IS CLICKED
					if ( $this.hasClass("browse-items__pagination__extend--prev") ) {
						if( $ms_ajax_paging.page - 1 < (($ms_ajax_paging.current * 3) - 2) ) {
							$ms_ajax_paging.prev--;
							$ms_ajax_paging.current--;
							$ms_ajax_paging.next--;

							// $ms_ajax_query.offset = ($ms_ajax_paging.current * $ms_ajax_query.posts_per_page * 3) - (9 * $ms_ajax_paging.next);
							$ms_ajax_query.offset = ($ms_ajax_paging.current * $ms_ajax_query.posts_per_page * 3) - (3 * $ms_ajax_paging.next);
							$ms_ajax_query.offset = ( $ms_ajax_query.offset < 0 )? 0 : $ms_ajax_query.offset;
						} else {
							$ms_ajax_query.offset = ($ms_ajax_paging.page - 2) * $ms_ajax_query.posts_per_page;
							$ms_ajax_paging.update_paging = false;
						}
					} else {
						// NEXT BUTTON CLICKED
						if( $ms_ajax_paging.page >= $ms_ajax_paging.current * 3 ) {
							$ms_ajax_query.offset = $ms_ajax_paging.current * $ms_ajax_query.posts_per_page * 3;
							$ms_ajax_paging.prev++;
							$ms_ajax_paging.current++;
							$ms_ajax_paging.next++;
						} else {
							$ms_ajax_query.offset = parseInt($ms_ajax_paging.page) * $ms_ajax_query.posts_per_page;
							$ms_ajax_paging.update_paging = false;
						}
					}

					$ms_ajax_paging.page = Math.round($ms_ajax_query.offset / $ms_ajax_query.posts_per_page) + 1;
					$ms_ajax_paging.update_paging = false;
				}

				MS.tax_ms_home_category._fetch();
			});
		},
		_renderFilteredProducts: function($itemsToBeRendered){
			var color = 1;
			var html = '';
			$('.browse-items .browse-items__item').remove();
			//for(var i = 0; i < $itemsToBeRendered.length; i++){
			for(var i = 0; i < $ms_ajax_query.posts_per_page; i++){
				var msTerms = "";
				var _image = "";

				$render = $itemsToBeRendered[i];
				if( typeof $render === "undefined" ) { continue; }
				if( color > 2 ){ color = 1; }

				for( var j = 0; j < $render.ms_terms.length; j++ ){
					msTerms += $render.ms_terms[j].slug + " ";
				}

				if( $render.ms_image_hover !== null ) {
					_image = '<img class="original" src="'+$render.ms_image+'" /><img class="hover-state" src="'+$render.ms_image_hover+'" />\n';
				} else {
					_image = '<img src="'+$render.ms_image+'" />\n';
				}

				html += '<article class="browse-items__item all color-'+color+' '+msTerms+'">\n' +
					'<a href="'+$render.ms_url+'">\n' +
						'<figure>\n' +
							_image+
						'</figure>\n' +
						'<h3>'+$render.post_title+'</h3>\n' +
						'<p>'+$render.ms_description+'</p>' +
					'</a>\n' +
				'</article>';

				color++;
			}
			$browse_items_container.prepend(html);
		},
		_drawButtons: function() {

			$(".browse-items__pagination__item").remove();

			$prevBtn.removeClass("is-shown");
			$nextBtn.removeClass("is-shown");

			if( $ms_ajax_paging.page > 1 ) {
				$prevBtn.addClass("is-shown");
			}

			if( $ms_ajax_paging.page < $ms_ajax_paging.pages ) {
				$nextBtn.addClass("is-shown");
			}

			if( $ms_ajax_paging.pages > 1 ) {
				for( var _x = 1; _x <= 3; _x++ ){
					var $current_page_number = (($ms_ajax_paging.current - 1) * 3) + _x;
					if( $current_page_number > $ms_ajax_paging.pages ){ break; }
					var _state = (Math.round($ms_ajax_paging.page) === $current_page_number)? "is-active" : "";

					$(".browse-items__pagination__extend--next").before( '<a data-target="'+ $current_page_number +'" class="ajax-pagination-item browse-items__pagination__item '+ _state +'" href="'+ $current_page_number +'">'+ $current_page_number +'</a>' );
				}
			}
		},
		_filter: function() {
			var _index = $(".browse-tabs__dropdown option[value="+$ms_ajax_paging.page+"]").index();
			$(".browse-filter").removeClass("is-active");
			$(".browse-items__pagination__item").removeClass("is-active");
			$($(".browse-items__pagination__item")[0]).addClass("is-active");
			$(".browse-filter[data-filter="+$ms_ajax_paging.page+"]").addClass("is-active");

			$(".browse-tabs__dropdown").prop('selectedIndex', _index).selectric("refresh");
			// IF A FILTER IS CLICKED
			$ms_ajax_query.offset = 0;

			$ms_ajax_query.tax_query_terms = ( $ms_ajax_paging.page === 'all' )? $ms_ajax_query.parent_term : [ $ms_ajax_paging.page ];

			$browse_items_container.attr("data-tax-query-terms", "['" + $ms_ajax_paging.page + "']" );

			MS.product_category_helper._resetPaging();
			$ms_ajax_paging.update_paging = true;
		},
		_fetch: function() {
			MS.loader.show(); // show preloader

			jQuery.get($ms_ajax_query_url, $ms_ajax_query, function(response) {

				$parsedResponse = JSON.parse(response);

				if( $ms_ajax_paging.update_paging ) {
					$ms_ajax_paging.total = Math.ceil(( $parsedResponse.length / $ms_ajax_query.posts_per_page ) / 3);
					$ms_ajax_paging.pages = Math.ceil( $parsedResponse.length / $ms_ajax_query.posts_per_page );
				}

				MS.product_category_helper._renderFilteredProducts($parsedResponse, $ms_ajax_query.posts_per_page);

			}).done(function(){

				MS.loader.hide();
				MS.product_category_helper._updateBrowseItemsHeight();
				MS.tax_ms_home_category._drawButtons();
				MS.product_category_helper._scrollToNav();

			});
		}
	};

/*************************************************************************************************/
/*********************************** BUSINESS CATEGORY *******************************************/
/*************************************************************************************************/

	MS.tax_ms_business_category = MS.tax_ms_home_category;

/************************************************************************************************/
/*********************************** HELPER FUNCITONS *******************************************/
/************************************************************************************************/

	MS.product_category_helper = {
		_resetPaging: function() {
			$ms_ajax_paging.prev = 0;
			$ms_ajax_paging.current = 1;
			$ms_ajax_paging.next = 2;
			$ms_ajax_paging.page = 1;
		},
		_scrollToNav: function() {
			var $topBlock = $(document).find(".browse-tabs");
			var _offset = null;
			if( $topBlock.length === 0 ) {
				$topBlock = $(document).find("section.browse-items");
				_offset = $topBlock.offset().top - 187;
			} else {
				_offset = $topBlock.offset().top;
			}

			$('html, body').animate({
				scrollTop: _offset
			}, 300);
		},
		_updateBrowseItemsHeight: function() {
			var _height = 0;
			$items = $(".browse-items .browse-items__item");
			$items.each(function() {
				$obj = $(this);
				var _box_height = $obj.height();
				if( _box_height > _height ) {
					_height = _box_height;
				}
			});
			$items.height(_height);
		},
		_renderFilteredProducts: function($itemsToBeRendered, $itemsMaxCount){
			var color = 1;
			var $browse_items_pagination = $(document).find(".browse-items__pagination");
			var html = '';

			$('.browse-items .browse-items__item').remove();
			//for(var i = 0; i < $itemsToBeRendered.length; i++){
			for(var i = 0; i < $itemsMaxCount; i++){
				var msTerms = "";
				var _image = "";

				$render = $itemsToBeRendered[i];
				if( typeof $render === "undefined" ) { continue; }
				if( color > 6 ){ color = 1; }

				for( var j = 0; j < $render.ms_terms.length; j++ ){
					msTerms += $render.ms_terms[j].slug + " ";
				}

				if( $render.ms_image_hover !== null ) {
					_image = '<img class="original" src="'+$render.ms_image+'" /><img class="hover-state" src="'+$render.ms_image_hover+'" />\n';
				} else {
					_image = '<img src="'+$render.ms_image+'" />\n';
				}

				html += '<article class="browse-items__item all color-'+color+' '+msTerms+'">\n' +
					'<a href="'+$render.ms_url+'">\n' +
						'<figure>\n' +
							_image +
						'</figure>\n' +
						'<h3>'+$render.post_title+'</h3>\n' +
						'<div>'+$render.ms_description+'</div>' +
						'<div class="cta">\n' +
							'<em>View</em> <i class="icon-arrow-right"></i>\n' + 
						'</div>' +
					'</a>\n' +
				'</article>';

				color++;
			}
			$browse_items_container.prepend(html);
		},
		// _handleBreakWord: function() {
		// 	if( $(".is-one-word").length ){ return; }
		// },
	};

})(jQuery);
