
MS = window.MS || {};

(function( $ ) {
	MS.search_results = {
		init: function() {
			$('select[name=search-filter]').change(function(e) {
	    		e.preventDefault();

	    		if( $(this).val()==="" ) {
	    			return;
	    		}

	    		document.location = $(this).val();
	    	});
		},
		finalize: function(){
			$(window).scroll();
		}
	};
})(jQuery);
