MS = window.MS || {};

(function($) {
	MS.media_room = {
		$moduleMediaBrowse: $(".page-template-page-media-room__media-browse"),

		init: function() {
			this.$moduleMediaBrowse.find(".filter-category").on("change", $.proxy(this.toggleCategory, this));
			this.$moduleMediaBrowse.find(".filter-category-sub").on("change", $.proxy(this.toggleSubCategory, this));
			this.$moduleMediaBrowse.find(".filter-category-sub").on("click", "li", $.proxy(this.toggleSubCategory, this));
			this.$moduleMediaBrowse.on("click", ".btn-load-more", $.proxy(this.loadMoreMediaItems, this));
		},

		loadMoreMediaItems: function(e) {
			var $target = $(e.target),
					$button = $target.closest(".btn-load-more");

			var postType = $button.data('post-type'),
					termId   = $button.data('term-id'),
					pageNum  = $button.data('page');

			$.ajax({
				url: msGlobal.ajaxUrl,
				type: "post",
				data: {
					action: "ajax_get_media_items",
					post_type: postType,
					term_id: termId,
					page_num: pageNum
				},
				beforeSend: function() {
					MS.loader.show();
				},
				success: function(result) {
					var $container = $button.closest(".category-sub");

					if (result) {
						$container.find(".medias").append(result);

						if ($(result).filter(".media-item").length < 9) {
							$container.addClass("no-more");
						}

					}
					else {
						$container.addClass("no-more");
					}

					MS.loader.hide();
				}
			});

			$button.data('page', parseInt(pageNum, 10) + 1);
		},

		toggleCategory: function(e) {
			var $target  = $(e.target),
					category = $target.val();

			this.$moduleMediaBrowse.find(".filter-category-sub")
				.filter("[data-category='" + category + "']")
				.addClass("is-active")
				.siblings(".filter-category-sub")
				.removeClass("is-active");

			this.$moduleMediaBrowse.find(".filter-category-sub")
				.filter("[data-category='" + category + "']")
				.closest(".selectric-filter-category-sub")
				.addClass("is-active")
				.siblings(".selectric-filter-category-sub")
				.removeClass("is-active selectric-is-active");

			this.$moduleMediaBrowse.find(".category")
				.filter("[data-category='" + category + "']")
				.addClass("is-active")
				.siblings(".category")
				.removeClass("is-active");
		},

		toggleSubCategory: function(e) {
			var $target     = $(e.target),
					subCategory = null,
					category    = $target.closest(".filter-category-sub").data("category");

			if ($target.is("select")) {
				subCategory = $target.val();
			}
			else {
				subCategory = $target.data("value");

				this.$moduleMediaBrowse.find("select.filter-category-sub")
					.filter("[data-category='" + category + "']")
					.val(subCategory);
			}

			this.$moduleMediaBrowse.find("ul.filter-category-sub")
				.filter("[data-category='" + category + "']")
				.find("[data-value='" + subCategory + "']")
				.addClass("is-active")
				.siblings("li")
				.removeClass("is-active");

			this.$moduleMediaBrowse.find(".category-sub")
				.filter("[data-category-sub='" + subCategory + "']")
				.addClass("is-active")
				.siblings(".category-sub")
				.removeClass("is-active");
		},

		finalize: function() {
			
		}
	};
})(jQuery);