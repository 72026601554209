
MS = window.MS || {};

(function( $, mCustomScrollbar ) {
	MS.scrollbar = {
		initiated: false,

		init: function () {
			var SCROLLER = this;
			$(window).load(function() {
				SCROLLER.horizontal_scroller();
			});
			$(window).resize(function(event) {
				if( true === MS.scrollbar.initiated ) { // prevent from firing while not yet initiated
					SCROLLER.horizontal_scroller('update');
				}
			});
		},
		horizontal_scroller: function(e) {
			var SCROLLER = this;
			$(".ms-scroller-x").each(function() {
				var $obj = $(this);
				var _width = null;
				var _height = null;
				var _scroller_height = 8;
				var _scroller_margin_top = 10;
				var _table_padding_bottom = 50;
				var _destroyed = false;

				if( $obj.parents('.responsive-table-wrapper').length > 0 ) {
					return;
				}

				if( $obj.find('.ms-comparison-table__wrapper').length > 0 || $obj.find('.ms-table__wrapper').length > 0 ) {
					var $table = $obj.find('table:not(.duplicate)').first();

					_width = $table.outerWidth(true) + 20;
					_height = $table.outerHeight(true) + _scroller_height + _scroller_margin_top;

					if( typeof(event) !== 'undefined' && event.type === 'resize' ) {
						$obj.mCustomScrollbar('destroy');
						$obj.removeAttr('style');
						$obj.find('.ms-comparison-table__wrapper, .ms-table__wrapper, table').removeAttr('style');
						MS.tables.handleCellWidths();
						_width = $table.outerWidth(true) + 20;
						_height = $table.outerHeight(true) + _scroller_height + _scroller_margin_top;
						_destroyed = true;
					}

					if( $table.hasClass('ms-comparison-table') ) {
						_height = _height + _table_padding_bottom + 40;
					}

					if ( $(window).width() < 760 || $table.hasClass('ms-comparison-table') || $table.find('tr').first().find('td').length > 5 || _width > $(window).width() ) {
						if( !_destroyed && typeof(event) !== 'undefined' && event.type === 'resize' ) {
							$obj.mCustomScrollbar({setWidth: _width});
						}

						// $obj.css({
						// 	height: _height
						// });
						$obj.find('.ms-comparison-table__wrapper, .ms-table__wrapper, table').css({
							width: _width
						});

						if( !$table.hasClass('ms-comparison-table') && typeof(event) !== 'undefined' && event.type === 'resize' ) {
							SCROLLER.init_horizontal_scroller($obj);
						}
					}
				} else if ( $(window).width()<760 ) {
					_width = $obj.find('li').first().outerWidth(true) * $obj.find("li").length;

					if( typeof(event) !== 'undefined' && event.type === 'resize' ) {
						$obj.mCustomScrollbar({setWidth: _width});
					}

					$obj.find("ul").first().width(_width);
				} else {
					$obj.mCustomScrollbar('destroy');
					$obj.find("ul").first().removeAttr('style');
					SCROLLER.init_horizontal_scroller($obj);
				}

				if( typeof(event) !== 'undefined' && event.type === 'resize' ) {
					$obj.mCustomScrollbar("update");
				} else {
					SCROLLER.init_horizontal_scroller($obj);
				}
			});
		},
		init_horizontal_scroller: function($obj) {
			$obj.mCustomScrollbar({
			    axis:"x",
			    mouseWheel: {axis: 'x', preventDefault: false, invert: true},
			    scrollInertia: 100,
			    live: true,
			    callbacks:{
					onInit: function(){
						// $('.ms-scroller-x .mCSB_dragger').html('Scroll <i class="icon-arrow-right"></i>');
						MS.scrollbar.initiated = true;

					},
					whileScrolling: function(){ 
						var $fleft = $obj.find('.frozen.left').first();
						if( $fleft.length > 0 ) {
							var $wrapper = $fleft.parents('.ms-scroller-x').first();
							var _left = Math.abs(this.mcs.left);
							var _minLeft = 46;

							if( _left >= 0 ) {
								$fleft.css('left', _left);
							} else {
								$fleft.css('left', 0);
							}

							if( $(window).width() < 480 ) {
								// _minLeft = 110;
								_minLeft = 0;
							}

							if( _left > _minLeft ) {
								$fleft.addClass('active');
								$wrapper.addClass('scrolled');
							} else {
								$fleft.removeClass('active');
								$wrapper.removeClass('scrolled');
							}
						}
					},
				}
			});
		}
	};
})(jQuery);