MS = window.MS || {};

(function($) {
	MS.header = {
		$document: $(document),
		$header: $("#header"),
		$navigation: $("#navigation"),

		init: function() {
			this.$document.on("scroll", $.proxy(this.toggleHeader, this));
			this.$header.on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", $.proxy(this.headerTransitionCompleted, this));

			this.$header.on("click", ".btn-search", $.proxy(this.expandSerach, this));
			this.$document.on("click", $.proxy(this.retractSearch, this));

			this.$document.scroll();

			this.submitSearch();
		},

		toggleHeader: function(e) {
			var self = this;

			if (!this.$header.hasClass("is-animating")) {
				if (this.$document.scrollTop() > 200) {
					if (!this.$header.hasClass("is-docked")) {
						this.$header
							.addClass("is-docked")
							.removeClass("is-expanded");

						$('body').addClass("nav-is-docked");

						setTimeout(function() {
							self.$header.addClass("is-animating is-expanded");
						}, 0);
					}

				}
				else {
					if (this.$header.hasClass("is-expanded") && this.$header.hasClass("is-docked")) {
						this.initiateHeaderTransmition();
					}
				}
			} else if( this.$header.hasClass("is-animating") && this.$header.hasClass("is-expanded") && this.$header.hasClass("is-docked") && this.$document.scrollTop() < 200  ) {
				this.initiateHeaderTransmition();
			}
		},

		initiateHeaderTransmition: function() {
			var self = this;
			this.$header
				.addClass("is-animating")
				.removeClass("is-expanded")
				.one("transitionend", function(e) {
					self.$header
						.removeClass("is-docked")
						.addClass("is-animating is-expanded");
					$('body').removeClass("nav-is-docked");
				});
		},

		headerTransitionCompleted: function(e) {
			this.$header.removeClass("is-animating");
			this.$document.scroll();
		},

		expandSerach: function(e) {
			var $searchForm = this.$header.find(".form-search");

			if (!$searchForm.hasClass("is-active")) {
				e.preventDefault();

				$searchForm
					.addClass("is-active")
					.find("input[type=text]")
					.focus();
			}
		},

		retractSearch: function(e) {
			if (!$(e.target).closest(".form-search").length) {
				$("#header").find(".form-search").removeClass("is-active");
			}
		},

		submitSearch: function(e) {
			$('form.form-search, form.search-form').submit(function(e) {
				e.preventDefault();
				$input = $(this).children('.search-field').first();

				if( $input.val()==="" ) {
	    			return;
	    		}

	    		document.location = '/search/' + $input.val();
			});
		},

		finalize: function() {
			
		}
	};
})(jQuery);