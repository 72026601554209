MS = window.MS || {};

(function($) {
	MS.modal = {
		init: function() {
			$(document).on("click", "a.ms-modal-trigger", this.showModal);
			$(document).on("click", ".ms-modal .icon-cross", this.hideModal);
			jQuery(document).bind('gform_post_render', function(){
			   MS.dropdown.updateDropdown();
			});
			$('body').append($('.ms-modal'));
		},

		showModal: function(e) {
			e.preventDefault();

			var $target  = $(e.target),
				$link    = $target.closest("a");

			$( $link.attr("href") ).addClass('open');
		},

		hideModal: function(e) {
			$('.ms-modal.open').removeClass('open');
			location.reload();
		},

		finalize: function() {
			
		}
	};
})(jQuery);