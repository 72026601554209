MS = window.MS || {};

(function($) {
	MS.page_template_page_about = {
		$locationModule: $(".page-template-page-about__our-location"),
    map: null,
    markers: [],
		mapOptions: {
			center: {
				lat: 37.09024,
				lng: -95.71289100000001
			},
			zoom: 4,
			disableDefaultUI: true,
			scrollwheel: false,
			options: {
				styles: [
					{
						"featureType": "water",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#d3d3d3"
							}
						]
					},
					{
						"featureType": "transit",
						"stylers": [
							{
								"color": "#808080"
							},
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"visibility": "on"
							},
							{
								"color": "#b3b3b3"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#ffffff"
							}
						]
					},
					{
						"featureType": "road.local",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"visibility": "on"
							},
							{
								"color": "#ffffff"
							},
							{
								"weight": 1.8
							}
						]
					},
					{
						"featureType": "road.local",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"color": "#d7d7d7"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"visibility": "on"
							},
							{
								"color": "#ebebeb"
							}
						]
					},
					{
						"featureType": "administrative",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#a7a7a7"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#ffffff"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#ffffff"
							}
						]
					},
					{
						"featureType": "landscape",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"visibility": "on"
							},
							{
								"color": "#efefef"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#696969"
							}
						]
					},
					{
						"featureType": "administrative",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"visibility": "on"
							},
							{
								"color": "#737373"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"color": "#d6d6d6"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#dadada"
							}
						]
					}
				],
			}
		},

		init: function() {
			var self = this;

			self.initMap();
			self.$locationModule.on("click", ".btn-cross", function(e) {
				self.hideLocationInfo(e, self);
			});

			self.$locationModule.on("click", ".btn-mining-type", function(e) {
				self.toggleMiningTypeInfo(e, self);
			});

			$(window).on("resize", function(e) {
				self.hideLocationInfo(e, self);
			});
		},

		initMap: function() {
			var self = this;

      self.map = new google.maps.Map(document.getElementById('map-locations'), this.mapOptions);

      // Clear active markers on drag
      self.map.addListener('drag', function(e) {
        self.hideLocationInfo(e, self);
      });

			self.$locationModule.find(".location-info").each(function() {
				var $this = $(this),
						lat   = $this.data("lat"),
						lng   = $this.data("lng"),
						label = $this.data("label"),
						icon  = $this.data("icon");

				var marker = new MarkerWithLabel({
					position: {
						lat: lat,
						lng: lng
					},
					map: self.map,
					draggable: false,
					labelContent: "<i class='icon' style='background-image: url(" + icon + ");'></i><span class='label'>" + label + "</span>",
					labelAnchor: new google.maps.Point(18, 18),
					labelClass: "marker",
					labelInBackground: true,
					icon: " "
        });

        self.markers.push(marker);

				google.maps.event.addListener(marker, "click", function(e) {
					self.showLocationInfo(e, self, marker);

					marker.set('labelClass', 'marker is-active');
          marker.label.draw();
          marker.label.setStyles();
				});

				google.maps.event.addDomListener(window, "resize", function(e) {
					var center = self.map.getCenter();
					google.maps.event.trigger(self.map, "resize");
					self.map.setCenter(center);
				});
			});
		},

		showLocationInfo: function(e, self, marker) {
			var $target = $(e.target),
					$marker = $target.closest(".marker"),
          label   = $(marker.label.labelDiv_).find('.label').html(); //Using the marker object where it will not return 'undefined'

			self.$locationModule
				.find(".marker")
        .removeClass("is-active");

      // $marker.addClass("is-active");

			self.$locationModule
				.find(".location-info")
				.removeClass("is-active")
				.filter("[data-label='" + label + "']")
				.addClass("is-active");

			var top = self.$locationModule.offset().top - 60;
			$(window).scrollTop(top);
		},

		hideLocationInfo: function(e, self) {
			self.$locationModule
				.find(".location-info")
        .removeClass("is-active");

      $.each(self.markers, function(i, marker) {
        marker.set('labelClass', 'marker');
        marker.label.draw();
        marker.label.setStyles();
      });

			self.$locationModule
				.find(".marker")
				.removeClass("is-active");
		},

		toggleMiningTypeInfo: function(e, self) {
			var $target = $(e.target),
					$icon   = $target.closest(".mining-type-icon"),
					type    = $icon.data("type");

			self.$locationModule
				.find(".mining-type-icon, .mining-type-info")
				.removeClass("is-active");

			self.$locationModule
				.find(".mining-type-icon[data-type='" + type + "']")
				.addClass("is-active");

			self.$locationModule
				.find(".mining-type-info[data-type='" + type + "']")
				.addClass("is-active");
		},

		finalize: function() {

		}
	};
})(jQuery);
