MS = window.MS || {};

(function( $, ddslick ) {
  MS.forms = {
    init: function () {
      $(window).load(function() {
        $(".gform_wrapper").find("select").addClass("ms-dropdown ms-dropdown--default");
      });
    },
  };
})(jQuery);
