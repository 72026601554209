
MS = window.MS || {};

(function($,ddslick) {
	MS.page_template_page_salt_selection_guide = {

		activeTop: null,
		activeBottom: null,

		init: function() {
			var _this = this;
			_this.handleFilter();
			// $('.filter-category, .filter-category-sub').prop('selectedIndex', 0).selectric('refresh');
			_this.categoryFilter(false);
			_this.subCategoryFilter();
			_this.dropDownWidthFix();
			$(window).resize(function(e) {
				_this.dropDownWidthFix();
			});
	    },

	    dropDownWidthFix: function() {
	    	var $wrapper = $('.selectric-wrapper');
			var $grid = $wrapper.parents('.grid.grid-1').first();
	    	if( $(window).width() < 760 ) {
				$wrapper.css('max-width', $grid.width()+'px');
			} else {
				$wrapper.css('max-width', 'none');
			}
	    },

	    handleFreezeTable: function() {
			var $table = $('.ms_ssg-table.active');
    		var $wrapper = $('<div class="freeze-wrapper"></div>');
    		var $cells = $();
    		$table.wrap($wrapper);

    		$wrapper = $table.parents('.freeze-wrapper').first();
    		var $frozedTop = $('<div class="frozen top"></div>');
    		var $category = $('<div class="frozen-category frozen-row"></div>');

    		var $frozedLeft = $('<div class="frozen left"></div>');
    		var $subCategory = $('<div class="frozen-sub-category frozen-columns"></div>');
    		// handle top
    		if( $table.find('tr').length > 3 ) {
    			$cells = $table.find('tr:first-child td:not(.hidden)');

	    		$.each($cells, function(index, val) {
	    			var _class = '';
	    			$cell = $(this);

	    			if( $cell.css('display') !== 'none' ) {
	    				var _text = $cell.text().trim();

		    			if( _text !== '' ) {
		    				_class = _class + 'b ';
		    			}

		    			if (typeof $cell.attr('class')  !== "undefined") {
		    				_class = _class + $cell.attr('class');
		    			}

		    			$f = $('<div class="' + _class + ' cell" style="width:' + ($cell.outerWidth() + 2) + 'px;height:' + $cell.outerHeight() + 'px;">' + _text + '</div>');

		    			$category.append($f);
	    			}	
	    		});

				$frozedTop.append($category);

	    		$wrapper.prepend($frozedTop);
	    		$frozedTop = $wrapper.find('.frozen.top').first();
	    		$frozedTop.find('.frozen-row').first().css('width', $table.css('width').replace('px',''));
	    		// $frozedTop.find('.frozen-row').first().css('width', $table.outerWidth() + 55); // This is just a quick fix
    		} // handle top

    		// handle left
    		if( $table.find('tr:not(.hidden)').first().find('td:not(.hidden)').length > 2 ) {
    			$cells = $table.find('tr:not(.hidden) td:first-child');

    			$.each($cells, function(index, val) {
	    			var _class = '';
	    			$cell = $(this);
	    			var _text = $cell.html();

	    			if( _text !== '' ) {
	    				_class = _class + 'b ';
	    			}

	    			if( index === 0 ) {
	    				_text = '<cite>' + _text + '</cite>';
	    			}

	    			if (typeof $cell.attr('class')  !== "undefined") {
	    				_class = _class + $cell.attr('class');
	    			}

	    			$f = $('<div class="' + _class + ' cell" style="height:' + $cell.outerHeight() + 'px;"><span>' + _text + '</span></div>');

	    			$subCategory.append($f);
	    		});

	    		$frozedLeft.append($subCategory);

	    		$wrapper.prepend($frozedLeft);
	    		$frozedLeft = $wrapper.find('.frozen.left').first();
	    		$frozedLeft.find('.frozen-columns').first().css({
	    			width: $cells.first().outerWidth() + 10
	    		});
    		}
		},

		handleFreezeScroll: function() {
			$(window).scroll(function(e) {
				var $st = $(window).scrollTop();
				var $wrapper = null;

				if( $('.freeze-wrapper').length === 0 ) {
					return;
				}

				if( $('.freeze-wrapper.active').length === 0 ) {
					$wrapper = $('.freeze-wrapper');
					var $table = $wrapper.find('table').first();
					 if( $st >= ($wrapper.offset().top - 60) && $st <= ($wrapper.offset().top + $wrapper.outerHeight() - 60 - $wrapper.find('.frozen.top').outerHeight() ) && $('.freeze-wrapper.active').length === 0 ) {
					 	$wrapper.addClass('active');
					 	var $lastRow = $table.find('tr:last-child td');
					 	MS.page_template_page_salt_selection_guide.activeTop = $wrapper.offset().top - 60;
					 	MS.page_template_page_salt_selection_guide.activeBottom = $wrapper.offset().top + $wrapper.outerHeight() - 60 - $wrapper.find('.frozen.top').outerHeight() - $lastRow.first().outerHeight();
					}
				} else {
					$wrapper = $('.freeze-wrapper.active').first();
					if( $st < MS.page_template_page_salt_selection_guide.activeTop || $st > ( MS.page_template_page_salt_selection_guide.activeBottom ) ) {
					 	$wrapper.removeClass('active');
						$wrapper.find('.frozen.top').first().css({
							top: 'auto',
							left: 'auto'
						}).removeClass('active');
					} else {
						var _top = $('#header .container').offset().top - MS.page_template_page_salt_selection_guide.activeTop;
						$wrapper.find('.frozen.top').first().css('top', _top).addClass('active');
					}
				}
			});
		},

		handleTableResize: function() {

			if( $('.freeze-wrapper').length === 0 ) {
				return;
			}

			var $wrapper = $('.freeze-wrapper');
			var $table = $wrapper.find('table').first();
			var $cells = $table.find('tr:first-child td:not(.hidden)');
			var _width = 0;
			// handle top
			$.each($cells, function(i, val) {
    			$cell = $(this);
    			$wrapper.find('.frozen.top .cell').eq(i).css({
    				width: $cell.outerWidth(),
    				height: $cell.outerHeight()
    			});
    		});

			if( $(window).width() > 759 ) {
				_width = $table.css('width').replace('px','') - 5;
				$cells.first().css('width', '250px');
			} else {
				_width = $table.outerWidth() - 5;
				$cells.first().css('width', '200px');
			}

    		$wrapper.find('.frozen.top .frozen-row').first().css({
    			width: _width
    		});

    		// handle left
    		$cells = $table.find('tr:not(.hidden) td:first-child');

			$.each($cells, function(i, val) {
    			$cell = $(this);
    			$wrapper.find('.frozen.left .cell').eq(i).css({
    				height: $cell.outerHeight()
    			});
    		});

    		$wrapper.find('.frozen.left .frozen-columns').first().css({
    			width: $cells.first().outerWidth()
    		});

			// update scroll position
			var $st = $(window).scrollTop();
    		if( $wrapper.length > 0 ) {
    			$table = $wrapper.find('table').first();
    			var $lastRow = $table.find('tr:last-child td');
	    		MS.page_template_page_salt_selection_guide.activeTop = $wrapper.offset().top - 60;
				MS.page_template_page_salt_selection_guide.activeBottom = $wrapper.offset().top + $wrapper.outerHeight() - 60 - $wrapper.find('.frozen.top').outerHeight() - $lastRow.first().outerHeight();

    			var _top = $('#header .container').offset().top - MS.page_template_page_salt_selection_guide.activeTop;
				$wrapper.find('.frozen.top').first().css('top', _top);
				$wrapper.find('.frozen.left').first().removeClass('active').css('left', 0);
				$('.ms-scroller-x').removeClass('scrolled');
    		}
		},
		handleFilter: function() {
			var _this = this;
			$( '.filter-category' ).change(function(e) {
				e.preventDefault();
				_this.categoryFilter(true);
				_this.dropDownWidthFix();
			});

			$('.filter-category-sub').change(function(e) {
				e.preventDefault();
				_this.subCategoryFilter();
				_this.dropDownWidthFix();
			});
		},

		categoryFilter: function(_reset_sub_category) {
			var _this = this;
			var $obj = $('.filter-category').first();

			// Update Dropdowns
			$('.container-filter-categroy-sub').find('.selectric-wrapper').removeClass('selectric-is-active');
			$('.container-filter-categroy-sub').find('select.is-active').removeClass('is-active');

			if( _reset_sub_category ) {
				$('.container-filter-categroy-sub').find('select').prop('selectedIndex', 0).selectric('refresh');
			}

			if( $obj.val() !== '' ) {
				var $sub_cat = $('.container-filter-categroy-sub').find('select[data-category='+$obj.val()+']').first();

				if( $sub_cat.length > 0 ) {
					$sub_cat.addClass('is-active');
					$sub_cat = $sub_cat.parents('.selectric-wrapper').first();
					$sub_cat.addClass('selectric-is-active');
				}
			}
				
			_this.hideTable();

			// Display Table
			// var $table = $('table.ms_ssg-table.active').first();

			// $table.find('.product-row').show();

			// if ( $table.length > 0 ) {
			// 	_this.removeTablePlugins($table);
			// }

			// if( $obj.val() !== '' ) {
			// 	$('.ms_ssg-no-table-notif').hide();
			// 	$('.ms_ssg-tables').show();
			// 	$table = $('.ms_ssg-table[data-category='+$obj.val()+']');
			// 	$table.show();
			// 	_this.attachTablePlugins($table);
			// } else {
			// 	$('table.ms_ssg-table').removeClass('active');
			// 	$('.ms_ssg-no-table-notif').show();
			// 	$('.ms_ssg-tables').hide();
			// }
			// _this.handleTableResize();
		},

		subCategoryFilter: function() {
			var _this = this;
			var $obj = $('.container-filter-categroy-sub select.is-active').first();

			if( $obj.length > 0 ) {
				_this.hideTable();
				if( $obj.val() !== '' ) {
					_this.showTable();
					var $table = $('table.ms_ssg-table.active').first();
					$table.find('td').hide().addClass('hidden');
					$table.find('td:first-child ,td[data-salt-use="' + $obj.val() + '"]').show().removeClass('hidden');
					$table.find('.product-row:not(.su-' + $obj.val() + ')').hide().addClass('hidden');

					_this.attachTablePlugins($table);
					_this.handleTableResize();
				}
			}
		},

		hideTable: function() {
			var _this = this;
			var $table = $('table.ms_ssg-table.active').first();

			if( $table.length > 0 ) {
				_this.removeTablePlugins($table);
				$table.find('td').hide().addClass('hidden');
			}

			$('table.ms_ssg-table').removeClass('active');
			$('.ms_ssg-no-table-notif').show();
			$('.ms_ssg-tables').hide();
		},

		showTable: function() {
			var $obj = $('.filter-category').first();
			$('.ms_ssg-no-table-notif').hide();
			$('.ms_ssg-tables').show();
			$table = $('.ms_ssg-table[data-category='+$obj.val()+']');
			$table.show().addClass('active');
			$table.find('.product-row').show();
			// _this.attachTablePlugins($table);
			// _this.handleTableResize();
		},

		removeTablePlugins: function($table) {
			$(".ms-scroller-x").removeClass('scrolled').mCustomScrollbar('destroy');

			$table.removeAttr('style')
				.removeClass('active')
				.hide();

			$table.find('tr').show().removeClass('hidden').removeClass('lavender white');
			$table.find('td').removeAttr('style').removeClass('hidden');

			$table.parent().find('.frozen').remove();
			$table.unwrap();
			$table.unwrap();
			$table.unwrap();
		},
		attachTablePlugins: function($table) {
			var _this = this;
			$table.addClass('active').show();
			$table.wrap('<div class="ms-scroller-x ms-scroller-x--table"><div class="ms-table__wrapper"></div></div>');
			MS.tables.handleCellWidths();

			if( $(window).width() < 760 ) {
				$table.find('tr:first-child td:first-child').css('width', '200px');
			}

	    	_this.handleFreezeTable();
			_this.handleFreezeScroll();
			MS.scrollbar.horizontal_scroller();

			$.each($table.find('tr.product-row:not(.hidden)'), function(index, val) {
				if( index%2===0 ) {
					$(this).addClass('lavender');
				} else {
					$(this).addClass('white');
				}
			});

			if( $table.find('tr.product-row:not(.hidden)').eq( $table.find('tr.product-row:not(.hidden)').length - 1 ).hasClass('lavender') ) {
				$table.find('tr:last-child').addClass('white');
			} else {
				$table.find('tr:last-child').addClass('lavender');
			}

			$(window).resize(function(e) {
				_this.handleTableResize();
			});
		},
	    finalize: function() {
	    	
	    }
	};
})(jQuery);