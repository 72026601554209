
MS = window.MS || {};

(function( $ ) {
	// Heritage Timeline
	MS.page_template_page_heritage_timeline = {
		delta: 500,
		timeout: false,
		rtime: new Date(),
		init: function() {

			MS.loader.show();
			$("body").addClass("no-scroll");

			var $eras = $(document).find('.era');
			var $el_tracker = $(document).find('.heritage-timeline__container__tracker');
			var $el_tracker_mobile = $(document).find('.heritage-timeline__container__tracker--mobile');

			$(window).load(function() {

				MS.loader.hide();
				$("body").removeClass("no-scroll");

				// waypoints
				var era_waypoint, tracker_waypoint, footer_waypoint;
				var parallax_position;

				// desktop tracker
				var $site_footer = $(document).find('#footer');
				var el_tracker_top = $el_tracker.offset().top;
				var el_tracker_height = $el_tracker.outerHeight();

				// mobile tracker
				var el_tracker_mobile_top = 60;
				var el_tracker_mobile_height;
				var el_tracker_mobile_limit;

				var resizeId;

				$el_tracker_mobile.animate({
					opacity: 1
				}, 300, function(){
					el_tracker_mobile_height = $el_tracker_mobile.outerHeight();
					el_tracker_mobile_limit = $site_footer.offset().top - el_tracker_mobile_height - 60;
				});

				$(window).on("resize", function(){
					el_tracker_top = $el_tracker.offset().top;
					el_tracker_height = $el_tracker.outerHeight();
					el_tracker_limit = $site_footer.offset().top - el_tracker_height - 60 - 80;

					el_tracker_mobile_height = $el_tracker_mobile.outerHeight();

					clearTimeout(resizeId);
    				resizeId = setTimeout(function(){
						// desktop
						$(window).scroll();
					}, 500);
				});

				$('.heritage-timeline__container__tracker li').on('click', function(e){
					e.preventDefault();
					$this = $(this);
					$('html, body').stop().animate({
						scrollTop : MS.page_template_page_heritage_timeline.getRelatedContent( $(this) ).offset().top - 60
					}, 'slow', function(){
						$('.heritage-timeline__container__tracker li').removeClass("active");
						$this.addClass("active");
					});
				});

				$('.heritage-timeline__container__tracker--mobile .selectric-ms-dropdown').on('change', function(e){
					var selectedEra = $("article[data-era='"+$(e.target).val()+"']").data("date");

					$('html, body').stop().animate({
						scrollTop : $('#era-' + $(e.target).val()).offset().top - 60
					}, 'slow', function(){
						$('.ms-dropdown').prop('selectedIndex', selectedEra).selectric('refresh');
					});
				});

				$eras.each(function(index){
					$this = $(this)[0];

					era_waypoint = new Waypoint({
						element: $this,
						handler: function() {
							MS.page_template_page_heritage_timeline.setTrackerHighlight(this.element);
						},
						offset: 100
					});
				});

				$(window).on('scroll', function(){
					var windowTop = $(window).scrollTop();

					$eras.each(function(index){
						$el = $(this);
						if( $el.find('.parallax-item').length ){
							if( $el.find('.parallax-item').attr('data-position') === 'top' ){
								$el.find('.parallax-item').css('transform', 'translateY('+ ((($el.offset().top - 50) - windowTop) - $el.find('.era-image').height()) +'px)');
							} else {
								$el.find('.parallax-item').css('transform', 'translateY('+ ((($el.offset().top - 50) - windowTop) + ($el.find('.era-image').height() / 2) )+'px)');
							}
						}
					});

					// desktop
					if (el_tracker_top < windowTop + 60){
						$el_tracker.find("ul").addClass('stuck');
						$el_tracker.find("ul").removeClass('unstuck');
					} else {
						$el_tracker.find("ul").removeClass('stuck');
					}

					if ($site_footer.offset().top <= windowTop + $(window).height()){
						$el_tracker.find("ul").removeClass('stuck');
						$el_tracker.find("ul").addClass('unstuck');
					}

					// mobile
					if (windowTop > 280){
						if( !$el_tracker_mobile.hasClass('stuck') ) {
							$el_tracker_mobile.addClass('stuck');
						}
					} else {
						$el_tracker_mobile.removeClass('stuck');
					}

				});

				$(window).scroll();
			});
		},
		getRelatedContent: function(el) {
			return $('.era[data-era=' + $(el).find("a").attr('href').replace("#", "") + ']');
		},
		setTrackerHighlight: function(el) {
			$('.heritage-timeline__container__tracker li').removeClass("active");
			$('.heritage-timeline__container__tracker li a[href=#' + $(el).data("era") + ']').parent("div").parent("li").addClass("active");
			$('.ms-dropdown').prop('selectedIndex', $(el).data('date')).selectric('refresh');
		},
		finalize: function(){
			$(window).scroll();
		}
	};
})(jQuery);
