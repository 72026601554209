
MS = window.MS || {};

(function( $ ) {
	MS.tabs = {
		init: function() {
			var $tab = $('.ms-tab');
			$tab.css('display', 'block');
			$('.ms-tab .ms-tab--menu li a').click(function(e) {
				e.preventDefault();
				$obj = $(this);
				$tab = $obj.parents('.ms-tab').first();
				$li = $obj.parent('li');
				$pointer = $tab.find('.ms-tab__pointer');
				$tab.find('li.active').removeClass('active');
				$li.addClass('active');
				$tab.find('.ms-tab--item').removeClass('active');
				$tab.find('.ms-tab--item[data-content='+$obj.data('content')+']').addClass('active');
				var pointer_position = $li.position().left + ( $li.width() / 2 ) - ( $pointer.outerWidth(true) / 2 );
				$pointer.css('left', pointer_position + 'px');
			});
			$tab.prepend('<i class="ms-tab__pointer"></i>');
			$tab.find('.ms-tab--menu li a').first().trigger('click');
			$(window).resize(function(e) {
				MS.tabs.update();
			});
		},
		update: function() {
			var $tabs = $('.ms-tab');
			$.each($tabs, function(index, val) {
				var $tab = $(this);
				var $li = $tab.find('li.active');
				var $obj = $li.find('a');
				var $pointer = $tab.find('.ms-tab__pointer');
				var pointer_position = $li.position().left + ( $li.width() / 2 ) - ( $pointer.outerWidth(true) / 2 );
				$pointer.css('left', pointer_position + 'px');
			});
		}
	};
})(jQuery);
