
MS = window.MS || {};

(function( $, ddslick ) {
	MS.dropdown = {
		init: function () {
			var _this = this;
			$(window).load(function() {
				_this.updateDropdown();
			});

			if ($(".ms-dropdown--multi").length) {
				$(document).on("click", this.clickHandler);

				$(".ms-dropdown--multi").each(function() {
					var $this = $(this);
					$this.val($this.data("default"));
				});

				if ($().mCustomScrollbar) {
					$(".ms-dropdown--multi").find(".scroll").mCustomScrollbar({
						scrollInertia: 0,
						theme: 'ms-scrollbar',
						mouseWheel:{ preventDefault: true }
					});
				}
			}
		},

		updateDropdown: function() {
			$('.gfield_select').addClass('ms-dropdown ms-dropdown--default');
			$('.ms-dropdown').selectric({
				onInit: function(e) {
					var $selectric = $(e).parents('.selectric-wrapper').first();
					$selectric.find('.button').html('');

					var $selectric_lis = $selectric.find('li');

					$selectric_lis.each(function(i, v){
						$(v).html(function(x, y){
							return y.replace(/®|&reg;/g, '<sup>&reg;</sup>');
						});
					});

					if ($().mCustomScrollbar) {
						$selectric.find(".selectric-scroll").mCustomScrollbar({
							scrollInertia: 0,
							theme: 'ms-scrollbar',
							mouseWheel:{ preventDefault: true },
							callbacks: {
								onInit: function(e) {
									$selectric.find(".mCS-ms-scrollbar").on("click", function(e) {
										e.stopPropagation();
									});
								}
							}
						});
					}
				},
				disableOnMobile: false
			});
		},

		clickHandler: function(e) {
			var $target   = $(e.target),
					$dropdown = $target.closest(".ms-dropdown--multi");

			if ($target.closest(".btn-back").length) {
				e.preventDefault();
				e.stopPropagation();

				MS.dropdown.retractDropdownMultiItems();
			}
			else if ($target.closest(".sub-item").length) {
				MS.dropdown.retractDropdownMultis();
				MS.dropdown.retractDropdownMultiItems();

				var $subItem = $target.closest(".sub-item"),
						label    = $subItem.html(),
						value    = $subItem.data("value");

				if (value) {
					$dropdown
						.val(value)
						.trigger("change")
						.find(".label")
						.html(label);
				}
			}
			else if ($target.closest(".item").length) {
				e.stopPropagation();

				var $item = $target.closest(".item");

				MS.dropdown.expandDropdownMultiItem($item);
			}
			else if ($target.closest(".ms-dropdown--multi").length) {
				MS.dropdown.toggleDropdownMulti($dropdown);
			}
			else {
				MS.dropdown.retractDropdownMultis();
				MS.dropdown.retractDropdownMultiItems();
			}
		},

		toggleDropdownMulti: function($dropdown) {
			$dropdown.toggleClass("is-expanded");
		},

		retractDropdownMultis: function() {
			var $dropdowns = $(".ms-dropdown--multi");

			$dropdowns.removeClass("is-expanded");
		},

		expandDropdownMultiItem: function($item) {
			var $dropdown = $item.closest(".ms-dropdown--multi");

			$dropdown.addClass("is-sub-expanded");
			$item.addClass("is-selected");
		},

		retractDropdownMultiItems: function() {
			var $dropdowns = $(".ms-dropdown--multi"),
					$items     = $dropdowns.find(".item");

			$dropdowns.removeClass("is-sub-expanded");
			$items.removeClass("is-selected");
		}
	};
})(jQuery);
