MS = window.MS || {};

(function( $ ) {
	MS.tables = {
		init: function () {
			$(window).load(function() {
				MS.tables.handleCellWidths();
				// MS.tables.handleGroupings();
			});
		},

		handleGroupings: function() {
			$.each($('table'), function() {
				var $table = $(this);
				var $first_row = $table.find('tr').first();
				var $groupings = $first_row.find("td[colspan]");
				var jumps = 0;
				if( $groupings.length > 0 ) {
					$columns = $first_row.find('td');
					$table.addClass('grouped');
					$.each($columns, function() {
						var $column = $(this);
						var _colspan = $column.attr('colspan');
						if( typeof _colspan !== typeof undefined && _colspan !== false ) {
							_colspan = parseInt(_colspan);
							var start = jumps;
							var end = jumps + _colspan - 1;
							jumps += _colspan;
							$.each($table.find('tr:not(:first-child)'), function() {
								$row = $(this);
								for( i = start, max = end; i <= max; i++ ) {
									if( i === start ) {
										$row.find('td').eq(i).addClass('gbl');
									} else if( i === max ) {
										$row.find('td').eq(i).addClass('gbr');
									} else {
										$row.find('td').eq(i).addClass('g');
									}
								}
							});
						} else {
							jumps++;
						}
					});
				}
			});
		},

		handleCellWidths: function() {
			// Check cell formats
			$.each($('table'), function() {
				var $table = $(this);
				var $first_row = $table.find('tr').first();

				if( !$table.hasClass('ms-comparison-table') ) {
					var $groupings = $first_row.find("td[colspan]");
					var $d = null;
					var _is_boolean = true;
					var _sw = 200;
					var _lw = 250;
					if( $groupings.length > 0 ) {
						$d = $table.find('tr').eq(2);
					} else {
						$d = $table.find('tr').eq(1);
					}
					$a = $d.find('td').first();
					if( $a.text().length > 10 ) {
						$first_row.find('td').first().width(_lw);
					} else if( $a.text().length <= 5 ) {
						$first_row.find('td').first().width(_sw);
						_lw = _sw;
					}
					$.each($d.find('td:not(:first-child)'), function(index, val) {
						$c = $(this);
						if( $c.text().length > 5 ) {
							_is_boolean = false;
						}
					});
					if( _is_boolean ) {
						$dataCells = $d.find('td:not(:first-child):not(.hidden)');
						$dataCells.width(_sw);
						$table.width( _sw*$dataCells.length+_lw );
					}
				} else {
					var _product_count = $first_row.find('td').length - 1;
					var _width = null;
					if( _product_count <= 4 ) {
						_width = (1120 - $first_row.find('td').first().width() - parseInt($first_row.find('td.current').css('padding-left').replace('px',''))) / _product_count;
						$first_row.find('td:not(:first-child)').width(_width);
					}
				}
			});
		}
	};
})(jQuery);
